/* ==========================================================================
    header-slider-3
    ========================================================================== */
    
.header-slider-3 {
    // background-color: white;
    padding-top: $navbar-height;
    
    .container-max {
        position: relative;
    }
    
    .content-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        align-content: flex-start;
        padding: 5rem;
        // height: 43rem;
        background: linear-gradient(to bottom, rgba(black, .5) 0%,rgba(black, 0) 100%);
    }
    
    .logo-header {
        padding: 3rem;
        width: 30rem;
        background-color: white;
        box-shadow: 0 1rem 3rem rgba(black, .2);
        transition: all ease-in-out .6s;

        &:hover {
            transform: scale(1.05)
        }
    
        >img {
            @extend .img-responsive;
            display: inline-block;
            width: 25rem;
        }
    }
        
    .col-header-right {
        @extend .hidden-xs;
        display: flex;
        flex-grow: 10;
        // flex-flow: row wrap;
        font-family: $headings-font-family;
    }

    .headline {
        font-size: $font-size-h4;
        padding: 2rem 5rem;
        // text-align: center;
        width: 100%;
        text-shadow: 0 0 1rem rgba(black, .3);
        color: white;

        @media(min-width: $screen-lg-min) {
            text-align: left;
            font-size: $font-size-h3;			
        }

        .uppercase {
            display: block;
            text-transform: uppercase;
        }

        strong {
            font-size: $font-size-h2;
            // color: $brand-primary;
        }
    }

    .navbar {
        background-color: transparent;

        .container-max {
            background-color: $brand-primary;
        }
    }

    .navbar-brand,
    .navbar-brand:hover {
        background-color: white;
        width: 0;
        opacity: 0;
        overflow: hidden;
        transition: all ease-in-out .3s;
        padding: 2rem;

        >img {
            width: 9rem;
        }
    }

    .navbar.affix {

        .navbar-brand {
            width: 13rem;
            opacity: 1;
        }
    }

    .carousel-control {
        display: none;
    }
} 