/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
  position: relative;
  border-bottom: solid 1px #e8e8e8;

 // &.affix {
  //  padding: .5rem 0;
 // }
  

  .content-header {
    // padding-top: 2rem;
  }

  .logo-header {

    >img {
      // @extend .margin-responsive;
      @extend .img-responsive;
      max-width: 100%;
      // margin-top: 10px;
      // margin-bottom: 10px;

      @media (max-width:$screen-xs-min) {
        display: block;
        margin: auto;
      }

      @media (max-width:$screen-sm-min) {
        display: block;
        margin: auto;
      }

    }
  }
}