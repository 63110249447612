/* ==========================================================================
    header-slider-1
    ========================================================================== */

    .header-slider-1 {
    @extend .no-pad;
    position: relative;

    @media(min-width: $screen-lg-min) {
        // margin-top: 3rem;
    }
    
    .logo-header {
        @extend .text-center;
        position: absolute;
        display: block;
        top: 0%;
        left: 10%;
        right: 10%;

        // @media(min-width: $screen-sm-min) {
        //     right: auto;
        // }
        
        >img {
            @extend .img-responsive;
            display: inline-block;
            width: 25rem;
        }
    }
}