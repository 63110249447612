/* ==========================================================================
   Accueil
   ========================================================================== */

.header-accueil {
  @extend .text-center;
}

.logo-accueil {
  display: inline-block;
  margin: 2% auto;

  >img {
    @extend .img-responsive;
    display: inline-block;
    width: 40rem;
  }
}

.main-accueil {
  min-height: 50vh;
}

// .btn-accueil {
//   @extend .btn;
//   @extend .btn-lg;
//   @extend .btn-primary;
//   margin: 3% auto;
//   text-transform: uppercase;
//   font-weight: 700;
// }

.footer-accueil {
  @extend .text-center;
  @extend .gouttiere;

  //@extend .bg1;

  padding: 5%;
  //background-color: rgba($body-bg, .8);
  overflow: hidden;

  h1 {
    font-size: $font-size-h3;
  }
}

.col-footer-accueil-left {
  @extend .col-sm-6;
  @extend .text-left;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.col-footer-accueil-right {
  @extend .col-sm-6;
  @extend .text-right;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
    padding-top: 20px;
  }
}



.container-bricks {
	padding: 1rem;

	@media(min-width: 970px) {
		display: flex;
		flex-flow: row wrap;
	}

	@media(min-width: 1170px) {
		padding: 1.5rem;
	}
}

.brick {
	padding: .5rem;
	width: 100%;

	@media(min-width: 1170px) {
		padding: 1rem;
	}

	>a {
		display: block;
		height: 30rem;
		border-radius: .5rem;
		overflow: hidden;
		box-shadow: 0 1rem 3rem -1rem rgba(black, .7);
	}

	.theme-1 {
		background: url(../images/charte/acc1.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-2 {
		background: url(../images/charte/lentilles.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-3 {
		background: url(../images/charte/acc3.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-4 {
		background: url(../images/charte/acc4.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-5 {
		background: url(../images/charte/acc5.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-6 {
		background: url(../images/charte/acc6.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-7 {
		background: url(../images/charte/para2.jpg) no-repeat center;
		background-size: cover;
	}
}

.brick-inner {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(black, .5);
		transition: ease 1s;
	}

	&:hover::after {
		background-color: rgba($brand-primary, .8);
	}
}

.brick-inner-2 {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(black, .5);
		transition: ease 1s;
	}

	&:hover::after {
		background-color: rgba($brand-primary, .8);
	}
}

.brick-title {
	text-align: center;
	position: relative;
	z-index: 1;
	font-size: 2.5rem;
	padding: 1.5rem;
	text-transform: uppercase;
	font-weight: 600;
	color: white;
	line-height: 1.2;

	@media(min-width: 750px) {
		font-size: 3.5rem;
	}
}

.brick-btn {
	position: relative;
	z-index: 1;
	text-transform: uppercase;
  font-weight: 300;
	color: white;
}

.brick-1 {

	@media(min-width: 970px) {
		width: 60%;
	}
}

.brick-2 {

	@media(min-width: 970px) {
		width: 40%;
	}
}

.logo-accueil-bis{
	 padding-right:50px;
}


.text-adresse{
	// height:20vh;
	padding-left:50px;
	border-left:solid 3px $brand-primary ;
}