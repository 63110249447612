
/* ==========================================================================
   header-basic-2
   ========================================================================== */

.header-basic-2 {
	padding-top: $navbar-height;
	background-color: white;
	
	.content-header {
		@extend .clearfix;
		display: flex;
		flex-flow: row wrap;
	}
	
	.logo-header {
		@extend .col-md-3;
		@extend .text-center;
		padding: 3rem;
		background-color: white;
	
		>img {
			@extend .img-responsive;
			display: inline-block;
			width: 25rem;
		}
	}
	
	.panel-tel-header {
		@extend .col-md-3;
		padding: 3rem;
		background-color: $gray-darker;
		font-size: $font-size-small;
		font-size: $font-size-large;
		color: $gray-lighter;
		
		>a {
			color: $brand-danger;
		
			&:hover {
				color: darken($brand-danger, 20%);
			}
		}
	}
	
	.content-headline {
		@extend .col-md-6;
		padding: 3rem;

		>strong {
			display: block;
			line-height: 1.5;
			text-transform: uppercase;
			color: $brand-primary;
		}
	}

	.navbar-brand,
	.navbar-brand:hover {
		background-color: white;
		width: 0;
		opacity: 0;
		overflow: hidden;
		transition: all ease-in-out .3s;

		>img {
			// @extend .img-responsive;
			width: 8rem;
		}
	}

	.navbar.affix {

		.navbar-brand {
			width: 12rem;
			opacity: 1;
		}
	}
} 