/* ==========================================================================
   header-slider-4
   ========================================================================== */

.header-slider-4 {
	padding-top: $navbar-height;
	background-color: $body-bg;
	display: flex;

	.container-header {
		// @extend .container-max;
		// @extend .no-pad;
		display: flex;
		width: 100%;
		flex-flow: row wrap;
	}

	.header-slider-4__col-left {
		width: 100%;
		padding: 3%;
		background-color: white;

		@media(min-width: $screen-md-min) {
			width: 30%;
		}
	}

	.header-slider-4__col-right {
		width: 100%;

		@media(min-width: $screen-md-min) {
			width: 70%;
		}
	}

	.bandeau-slider {
		margin: 0;

		img {
			display: inline-block;
			height: 45rem;
		}
	}

	.logo-header {
		display: inline-block;
		margin-bottom: 5rem;
	
		>img {
			padding-top: .4rem;
			@extend .img-responsive;
			display: inline-block;
			width: 25rem;
		}
	}

	.navbar-brand,
	.navbar-brand:hover {
		background-color: white;
		width: 0;
		opacity: 0;
		overflow: hidden;
		transition: all ease-in-out .3s;

		>img {
			// @extend .img-responsive;
			width: 8rem;
		}
	}

	.navbar.affix {

		.navbar-brand {
			width: 12rem;
			opacity: 1;
		}
	}

	.panel-header {
		@extend .text-center;
		padding: 0 15%;
	}
}