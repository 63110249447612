

/* ==========================================================================
	Headers
	========================================================================== */

@import "header-basic";
@import "header-basic-2";
@import "header-slider-1";
@import "header-slider-2";
@import "header-slider-3";
@import "header-slider-4";
@import "header-slider-5";

@mixin breakpoint($point) {
  @if $point == lg {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  } @else if $point == md {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  } @else if $point == sm {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $point == xs {
    @media only screen and (min-width: $screen-xs-min) {
      @content;
    }
  }
}

@mixin background(
  $imgpath: "",
  $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))
) {
  background: $overlay, url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

@mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
  background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
    url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

//********** FLEXI BOOTSTRAP FROM Brian Willis

@media only screen and (min-width: $screen-md-min) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-row.row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}




/* ==========================================================================
	Global
	========================================================================== */

body {
	overflow-x: hidden;
	//background: url(../images/homeBG.jpg) no-repeat center fixed;
	//background-size: cover;

	p{
		@media (max-width:1280px) {
			font-size:14px !important;
		}
	
		

	}
}

*::selection {
	background-color: #B3D4FB;
}

h1, h2, h3, h4, h5,h6,
.h1, .h2, .h3, .h4,.h5,.h6 {

	small {
		display: block;
		padding-top: 5px;
	}
}


.contact-btn {
  position: absolute;
  overflow: hidden;
  top: 3rem;
  right: -5.4rem;
  background-color: red;
  padding: 1rem 1.6rem;
  transition: right 0.3s;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.5);
}
.contact-btn:hover {
  background-color: darken(red, 10%);
  right: 0;
}

.contact-btn__link {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
}

.contact-btn__link:before {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.1rem;
  width: 1rem;
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false'  data-icon='envelope' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath fill='currentColor' d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z' class=''%3E%3C/path%3E%3C/svg%3E");
  filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(76deg)
    brightness(96%) contrast(110%);
  margin-right: 1.1rem;
}
.contact-btn__link:hover {
  text-decoration: none;
  color: white;
}

.btn-outline-primary {
  border: 1px solid $brand-primary;
  border-color: $brand-primary;
  background-color: transparent;
  &:hover {
    color: white;
    background-color: $brand-primary;
    border-color: $brand-primary;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.btn-outline-success {
  border: 1px solid $brand-success;
  border-color: $brand-success;
  background-color: transparent;
  &:hover {
    color: white;
    background-color: $brand-success;
    border-color: $brand-success;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.card {
  @include padding-top(1.25em);
  @include padding-bottom(1.25rem);
  @include padding-left(1.25rem);
  @include padding-right(1.25rem);
  border-radius: $border-radius-base;
  height: 100%;
}

.card-border {
  @include padding-top(1.25em);
  @include padding-bottom(1.25rem);
  @include padding-left(1.25rem);
  @include padding-right(1.25rem);
  border-radius: $border-radius-base;
  border: 1px solid #ccc;
  height: 100%;
}

.header-basic-2 {
  position: relative;
  //margin-top: 35px; // If e-commercer bar
  border-bottom: solid 1px #e8e8e8;
  .content-header {
    // padding-top: 2rem;
  }
  .logo-header {
    > img {
      // @extend .margin-responsive;
      //@extend .img-responsive;
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: $screen-xs-min) {
        display: block;
        margin: auto;
      }
      @media (max-width: $screen-sm-min) {
        display: block;
        margin: auto;
      }
    }
  }
}


// @include breakpoint(sm) {
//   .navbar-nav > li:last-child {
//     padding: 0.4rem 0;

//     a {
//       border: 2px solid $brand-primary;
//       border-radius: $btn-border-radius-base;
//       padding: 0.5rem 1.5rem;
//       color: $brand-primary;

//       &:hover {
//         border: 2px solid darken($brand-primary, 10);
//         background: darken($brand-primary, 10);
//         color: white;
//       }

//       &::after {
//         display: none;
//       }
//     }
//   }
// }


//********* FONTS

.hero__t {
  @include font-size(3.7rem);
  line-height: 1.1;
  font-weight: 700;
  @include margin-bottom(1rem);
  margin-top: 0;
}

.hero__st {
  @include font-size(1.618rem);
  @include margin-bottom(1rem);
}

.t {
  margin-top: 0;
  font-weight: 700;
  @include margin-bottom(1rem);
  @include font-size(3rem);
}
.t2 {
  margin-top: 0;
  font-weight: 700;
  @include margin-bottom(1rem);
  @include font-size(2.4rem);
}

.st {
  font-weight: 700;
//   @include font-size(1.418rem);
}
.st2 {
  font-weight: 700;
  @include font-size(1.2rem);
}
.p {
  @include font-size(1.1rem);
}
.p2 {
  @include font-size(1rem);
}

.list {
  list-style: none;
  @include font-size(1rem);
  padding-left: 0.1rem;
  &__item {
    line-height: 1.8;
    position: relative;
    &:before {
      content: "+";
      font-weight: 700;
      color: $brand-primary;
      margin-right: 0.8rem;
      line-height: 1.8;
      @include font-size(1rem);
    }
  }
}

.list-line {
  position: relative;
  padding-left: 0.2rem;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    border: 1px dashed $brand-primary;
  }
  &__item {
    display: block;
    position: relative;
    left: -20px;
    background-color: #eee;
    padding: 5px;
    padding-left: 20px;
    margin: 40px 0px;
  }
}

ul.listIcon {
  list-style: none;
  padding: 0;
}
ul.listIcon > li {
  padding-left: 1.3em;
}
ul.listIcon > li:before {
  content: "\f058"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

.blockquote {
  position: relative;
  border-left: transparent;
  font-style: italic;
  @include font-size(1.2rem);
  &::before {
    content: "\201C"; /*Unicode for Left Double Quote*/

    /*Font*/
    font-family: Georgia, serif;
    @include font-size(3rem);
    font-weight: bold;
    color: #999;

    /*Positioning*/
    position: absolute;
    left: -5%;
    top: -5%;
  }

  &::after {
    /*Reset to make sure*/
    content: "";
  }
}

span.underline {
  background-image: linear-gradient(
    90deg,
    lighten($brand-primary, 20%),
    lighten($brand-primary, 20%)
  );
  background-repeat: no-repeat;
  background-size: 100% 1rem;
  background-position: 0 115%;
}

.lineafter-center {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: $brand-primary;
  }
}

.lineafter-center__white {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: white;
  }
}

.lineafter {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem auto;
    border-radius: 35px;
    background: $brand-primary;
  }
}

.lineafter__white {
  &:after {
    content: "";
    display: block;
    height: 0.5rem;
    width: 25%;
    margin: 2rem 0;
    border-radius: 35px;
    background: white;
  }
}

.big-t {
  font-size: 3rem;
  color: $brand-primary;
  font-weight: 800;
  font-family: sans-serif;
}

//********** DISPLAY

@include breakpoint(sm) {
  .d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.basis-card {
  flex-basis: 18rem;
}

.relative {
  position: relative;
}

//********** SPACINGS

.s {
  @include padding-top(10rem);
  @include padding-bottom(10rem);
}

.s2 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.hero {
  @include padding-top(12rem);
  @include padding-bottom(12rem);
}

.mt-1 {
  @include margin-top(1rem);
}

.mt-2 {
  @include margin-top(2rem);
}

.mt-3 {
  @include margin-top(3rem);
}

.mt-4 {
  @include margin-top(4rem);
}

.mt-5 {
  @include margin-top(5rem);
}

.mb-1 {
  @include margin-bottom(1rem);
}

.mb-2 {
  @include margin-bottom(2rem);
}

.mb-3 {
  @include margin-bottom(3rem);
}

.mb-4 {
  @include margin-bottom(4rem);
}

.mb-5 {
  @include margin-bottom(5rem);
}

.my-1 {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

.my-2 {
  @include margin-top(2rem);
  @include margin-bottom(2rem);
}

.my-3 {
  @include margin-top(3rem);
  @include margin-bottom(3rem);
}

.my-4 {
  @include margin-top(4rem);
  @include margin-bottom(4rem);
}

.my-5 {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
}

.mx-1 {
  @include margin-left(1rem);
  @include margin-right(1rem);
}

.mx-2 {
  @include margin-left(2rem);
  @include margin-right(2rem);
}

.mx-3 {
  @include margin-left(3rem);
  @include margin-right(3rem);
}

.mx-4 {
  @include margin-left(4rem);
  @include margin-right(4rem);
}

.mx-5 {
  @include margin-left(5rem);
  @include margin-right(5rem);
}

.pt-1 {
  @include padding-top(1rem);
}

.pt-2 {
  @include padding-top(2rem);
}

.pt-3 {
  @include padding-top(3rem);
}

.pt-4 {
  @include padding-top(4rem);
}

.pt-5 {
  @include padding-top(5rem);
}

.pb-1 {
  @include padding-bottom(1rem);
}

.pb-2 {
  @include padding-bottom(2rem);
}

.pb-3 {
  @include padding-bottom(3rem);
}

.pb-4 {
  @include padding-bottom(4rem);
}

.pb-5 {
  @include padding-bottom(5rem);
}

.py-1 {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.py-2 {
  @include padding-top(2rem);
  @include padding-bottom(2rem);
}

.py-3 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.py-4 {
  @include padding-top(4rem);
  @include padding-bottom(4rem);
}

.py-5 {
  @include padding-top(5rem);
  @include padding-bottom(5rem);
}

.px-1 {
  @include padding-left(1rem);
  @include padding-right(1rem);
}

.px-2 {
  @include padding-left(2rem);
  @include padding-right(2rem);
}

.px-3 {
  @include padding-left(3rem);
  @include padding-right(3rem);
}

.px-4 {
  @include padding-left(4rem);
  @include padding-right(4rem);
}

.px-5 {
  @include padding-left(5rem);
  @include padding-right(5rem);
}

.mr-1 {
  @include margin-right(1rem);
}

.mr-2 {
  @include margin-right(2rem);
}

.mr-3 {
  @include margin-right(3rem);
}

hr.hr-white {
  border-top: 1px solid white;
}

hr.hr-light {
  border-top: 1px solid $gray-light;
}

hr.hr-gray {
  border-top: 1px solid $gray;
}

hr.hr-graydark {
  border-top: 1px solid $gray-dark;
}

//********* TEXT

.text-wh {
  color: white;
}

.text-dark {
  color: #333;
}

.strong {
  font-weight: 600;
}

//********* EFFECTS

.shadow {
  box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}


//********* BG

.bg-1 {
  @include background2("../images/charte/", #000, 0.5);
}

.bg-2 {
  @include background2("https://picsum.photos/id/1018/1920/1280");
}

.bg-3 {
  @include background2("https://picsum.photos/id/1018/1920/1280");
}

.bg-gradient {
  @include background("", linear-gradient(45deg, #e66465, #9198e5));
}

.bg-white {
  background-color: white;
}

.bg-softgray {
  background-color: #eee;
}

.bg-softgray-g {
  @include background("", linear-gradient(180deg, #e7e7e7, #ffffff));
}

.bg-darkgray {
  background-color: #111;
}

.bg-danger {
  background-color: $brand-danger !important;
  color: white;
}

.bg-primary-2 {
  background-color: lighten($brand-primary, 10%);
}

.bg-primary-3 {
  @include background2("", $brand-primary, 0.5);
}

.bg-success {
  background-color: $brand-success;
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 5vw solid white;
}

.after-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
}




////////////////////////////////////// apres le code de niko//////
.page-header-top {
	@extend .text-center;
	margin: 3rem 0;
	// position: relative;
	// padding-bottom: 3rem;
	// 
	//  &:after{
	// 	content : '';
	// 	position: absolute;
	// 	bottom: 0;
	// 	left:0;
	// 	width: 4rem;
	// 	height: .1rem;
	// 	background-color: $brand-primary;
	// }

	>h1 {
        @extend .tac;
		margin: 0;
	}
}

.btn,
a,
button {
	outline: 0 !important;
	transition: all linear 0.3s;
}

// .thumbnail, .panel, .well {
// 	box-shadow: 0 1rem 3rem rgba(black, .1);
// }

.container-max {
	@extend .container-fluid;
	max-width: 1440px;
}

.no-pad {
	padding: 0;
}

//********* OTHERS

.no-gutter {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.embed-5 {
  padding-bottom: 30rem;
}

.h-100 {
  height: 100%;
}

// .affix {
//   top: 0;
//   right: 0;
//   left: 0;
// }



/* ==========================================================================
	Navbar
	========================================================================== */

.navbar-centered {

	@media (min-width: $grid-float-breakpoint) {
		.navbar-nav {
			display: inline-block;
			float: none;
			vertical-align: top;
		}

		.navbar-collapse {
			text-align: center;
		}
	}
}

.navbar {
//	@extend .navbar-centered; // pour centrer le contenu de la navbar.
}

.navbar-nav {
	 @extend .navbar-right; // pour aligner � droite.

	>li {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 10px;
			left: 50%;
			right: 50%;
			height: 0;
			background-color: $brand-primary;
			opacity: 0;
			transition: all ease-in-out .3s;
		}

		&:hover::after,
		&:active::after,
		&:focus::after,
		&.active::after {
			left: 1rem;
			right: 1rem;
			height: .13rem;
			opacity: 1;
		}
	}
}

.index-nav {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}

/* ==========================================================================
	Navbar e-commerce
	========================================================================== */

.navbar-top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	background-color: white;
	padding: 0 1rem;
	font-size: 1.4rem;

	>a,
	>button {
		border: none;
		display: inline-block;
		background-color: transparent;
		padding: 0 1rem;
		height: 3.5rem;
		line-height: 3.5rem;
		border-left: solid 1px $gray-lighter;
		color: $gray-darker;

		&:hover {
			color: $brand-primary;
		}

		>i {
			margin-right: .5rem;
		}
	}
}

.dropdown-panel {
	position: absolute;
	z-index: 3000;
	top: 3.5rem;
	right: 0;
	width: 50rem;
	max-width: 100%;
	padding: 3rem;
	background-color: white;
	box-shadow: 0 1rem 3rem rgba(black, .1);
}

.dropdown-search {
	@extend .dropdown-panel;
}

.dropdown-cart {
	@extend .dropdown-panel;
}

.dropdown-account {
	@extend .dropdown-panel;
}

.btn-close {
	@extend .btn;
	@extend .btn-default;
	@extend .btn-sm;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}


/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}



/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 70rem;
}

.container-main {
	@extend .container-max;
}

/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	background-color: $body-bg;

	a {
		color: black;
	}
}

.container-footer {
	@extend .container-max;
	padding: 6rem;

	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

.footer-main {
	@extend .clearfix;
	border-top: solid 1px $brand-primary;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;
	line-height: 2.3rem;

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;
	line-height: 2.3rem;
}

ul.list-logo-footer {
	@extend .list-inline;
	margin-top: 3rem;
	padding-top: 3rem;
	border-top: solid 1px $gray-lighter;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}

	img {
		height: 37px;
	}
}

.panel-ref-footer {
	@extend .well;
}

.content-slider {
	margin: 3rem auto;
	max-width: 100rem;
	box-shadow: $box-shadow;
}




////////////////******************//////////////////

.divider {
	height: 20px;
	background: url(../images/charte/divider.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}

.divider-2 {
	height: 20px;
	background: url(../images/charte/divider-2.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}


.flex1 {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		display: block
	}
}

.flex1bis {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		// display: block
	}
}

.flexbase {
	display: flex;
	//flex-direction: column;


	@media (max-width:$screen-xs-min) {
		flex-direction: column;
	}

	@media (max-width:$screen-sm-min) {
		flex-direction: column;
	}
}

.flexcol {
	display: flex;
	flex-direction: column;


	@media (max-width:$screen-xs-min) {
		flex-direction: column;
	}

	@media (max-width:$screen-sm-min) {
		flex-direction: column;
	}
}

.flex1bis {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		// display: block
	}
}


.flexcenter {
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
}

.flexcenter-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.flexnav {
	display: flex;
	align-items: center;
	justify-content: center;

	@media(max-width:$screen-sm-min) {
		display: block
	}
}


.blockquote-center {
	@extend .text-center;
	max-width: 100rem;
	padding: 8rem 5rem 3rem;
	margin: 5rem auto;
	border: none;
	background-color: white;
	font-family: $font-family-serif;
	font-style: italic;
	position: relative;
	border-radius: 5px;

	&::before {
		content: '\f10d';
		font-family: 'fontAwesome';
		color: $brand-primary;
		font-style: normal;
		position: absolute;
		top: -4rem;
		left: 50%;
		width: 8rem;
		height: 8rem;
		margin-left: -4rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

}

.lead2 {
	font-weight: 300;
	font-size: 120%;
}

///////// TA

.ta {
	@extend .text-left;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	text-transform: uppercase;
	line-height: 1.5;

	font-family: 'Playfair Display', serif;
	font-weight: 600;

	small {
		line-height: 1.5
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 4rem;
		height: 4px;
		background-color: $brand-primary;
	}
}

.ta-inverse {
	@extend .text-left;
	@extend .ta;

	color: white;


	&::after {
		background-color: white;
	}
}


.tac {
	@extend .text-center;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	line-height: 1.5;

	font-family: 'Playfair Display', serif;

	small {
		line-height: 1.5
	}


	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 4rem;
		height: 2px;
		transform: translateX(-50%);
		background-color: $brand-primary;
	}
}

.tac-2 {
	@extend .text-center;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	// color: $brand-primary;
	line-height: 1.5;

	font-family: 'Playfair Display', serif;

	small {
		line-height: 1.5
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 4rem;
		height: 2px;
		transform: translateX(-50%);
		background-color: white;
	}
}

/////////// fleche

.btn-fleche {
	@extend .btn-accueil;
	background: $brand-primary;
	white-space: normal;
	position: relative;
	padding: 10px 50px;
	border: 2px solid;
	color: white;
	font-weight: bold;
	border-radius: 50px;
	border: solid 1px $brand-primary;
	margin-bottom: 50px;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;


	&:hover {
		background: white;
		color: $brand-primary;
		border-radius: 50px;
		border: solid 1px $brand-primary;
		padding-right: 70px;
	}

	&::after {
		position: absolute;
		margin-left: 20px;
		content: "\f061";
		font-family: fontAwesome;
		color: $brand-primary;
		-webkit-transition: all .8s ease-out .0s;
		transition: all .8s ease-out .0s;
	}
}

.btn-fleche-2 {
	@extend .btn-accueil;
	background: white;
	white-space: normal;
	position: relative;
	padding: 10px 50px;
	border: 2px solid;
	color: $brand-primary;
	font-weight: bold;
	border-radius: 50px;
	border: solid 1px $brand-primary;
	margin-bottom: 50px;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;

	&:hover {
		background: $brand-primary;
		color: white;
		border-radius: 50px;
		border: solid 1px white;
		padding-right: 70px;
		-webkit-transition: all .2s ease-out;
		transition: all .2s ease-out;
	}

	&::after {
		position: absolute;
		margin-left: 20px;
		content: "\f061";
		font-family: fontAwesome;
		color: white;
		-webkit-transition: all .8s ease-out .0s;
		transition: all .8s ease-out .0s;
	}
}

.btn-fleche-inverse {
	@extend .btn-fleche;
	border: 2px solid;
	color: $brand-primary;
	border: solid 1px $brand-primary;

	&:hover {
		background: $brand-primary;
		color: white;

	}

	&::after {
		color: white;
	}
}



.btn-perso {
	@extend .btn-accueil;
	background: $brand-primary;
	color: white;
	border: solid 2px white;
	border-radius: 50px;
	padding: 15px 50px;
	margin: 15px auto;

	&:hover {
		background: white;
		color: $brand-primary;
		border-radius: 50px;
		border: solid 2px $brand-primary;
	}

	.fa {
		margin-right: 10px;
	}
}

.btn-perso-inverse {
	background: white;
	color: $brand-primary;
	border: solid 1px $brand-primary;
	border-radius: 50px;
	padding: 15px 50px;
	margin: 15px auto;

	.fa {
		margin-right: 10px;
	}
}

.fa-arrow-right {
	margin: 5px 10px 5px 20px;
}

.fa-chevron-right {
	margin: 5px 10px 5px 20px;
}

.yo {
	color: $brand-success;
	font-size: 180%;
}

.majuscule {
	font-weight: 700;
	font-size: 200%;
	text-transform: uppercase;
}


.color-p {
	color: $brand-primary;
}


.color-s {
	color: $brand-success;
}


// constante

// constante

.format {
	padding: 50px 0px;
}

.format2 {

	padding: 75px 0px;
}

.format3 {

	padding: 100px 0px;
}


.fiche-popup {
	margin-bottom: 0px;
}


.margin-responsive {

	@media (max-width:$screen-sm-min) {
		margin-top: 100px;
		margin-bottom: 100px;
	}

	@media (max-width:$screen-xs-min) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

.s-actu {
	@extend .format3;
		background: rgba($brand-primary, 0.8);

	.fiche-popup {
			border-radius: 5px;
			//box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
			margin-bottom:0px;
        	border: solid 1px $brand-primary;
			//padding-bottom:0px;
		}

	.slider-popup{
			margin-bottom:0px;
		}
}

.smap {

	.map {
		padding-bottom: 80vh;
	}

	.block1 {
		padding: 3%;
		background: $brand-primary;
		color: white;
	}
}

.sha1 {
	box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}



.p5 {
	@include padding-top(5rem);
	@include padding-bottom(5rem);
	@include padding-left(5rem);
	@include padding-right(5rem);
    
    @media (max-width:1280px) {
		@include padding-top(3rem);
		@include padding-bottom(3rem);
		@include padding-left(3rem);
		@include padding-right(3rem);
	}


}

// .nav>li>a {
// 	padding: 10px 15px;

// 	@media (max-width:1280px) {
// 		font-size: 14px;
//		padding: 10px 11px;

// 	}

// }

blockquote {
	border-left: solid 5px $brand-primary;
	font-size: 16px;
}

.st {
	font-weight: bolder;
}

.bg1 {
	background: rgb(245, 245, 245);
}

.bg-white {
	background: rgb(255, 255, 255);
}

.gouttiere{
	margin:20px;
}


////**** section ****/////

.s1 {
	@extend .format;
	position:relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom:0;
		width:100%;
		height: 70%;
		@extend .bg1;

		@media (max-width:1280px) {
				height: 100%;
		}
	}

	.vitrine{
		max-height:200px;
	}

	.image-shadow{
		  box-shadow: -10px 10px 0px rgba($brand-primary, 1);
	}
}

.s2b {
	@extend .format;
	position:relative;
	// display:flex;
	// align-items: flex-end;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom:0;
		width:100%;
		height: 100%;
		@extend .bg1;
	}

	.vitrine{
		max-height:200px;
	}

	.block1{
		margin-top:-200px;

		@media (max-width:1440px) {
				margin-top:-100px;
		}

		@media (max-width:1280px) {
				margin-top:0px;
		}

		img{
			@extend .margin-responsive;
		}

	
	}

	.block2{
		margin-top:0;
			img{
			@extend .margin-responsive;
		}
	}

	.image-shadow{
		  box-shadow: 10px 10px 0px rgba($brand-primary, 1);
	}


}

.s3 {
	@extend .format;
	background: rgba($brand-primary, 0.918);

	@media (max-width:$screen-xs-min) {
			background: rgba($brand-primary, 0.918) !important;
	}
	

	.block1{
		height:70vh;
		background: white;
		border-radius:0 15px 15px 0;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
		// color:white;
	}


}

.s4{
	.block3{
		margin-top:-250px;

		
		@media (max-width:1440px) {
				margin-top:-100px;
		}

		@media (max-width:1280px) {
				margin-top:0px;
		}

	}

		img{
			@extend .margin-responsive;
		}

	.image-shadow{
		  box-shadow: 10px -10px 0px rgba($brand-primary, 1);
	}
}

.s-avis{

	border:solid 10px $brand-primary;

}

.s-portrait{

	p{
		color: $brand-primary;
		@extend .lead2;
		@extend .st;

	}


}

.vid{
	// padding-bottom:500px;
}

// <!-- #include file="includes/base-popup/popup-inc.asp"-->

// <!--#include file="includes/base-diaporama/diaporama-bootstrap-inc.asp"-->

//  <!-- #include file="includes/base-logo/p-logo.asp"-->

// <img src="<%=siteweb%>/images/charte/logo-header.png" alt="" class="img-responsive center-block">

// <i class="fa fa-check-square-o fa-1x mr-1" aria-hidden="true"></i>

// <a href="<%=siteweb%>/presentation/"></a>

// <p><a href="<%=siteweb%>/contact/" class="btn-fleche">Nous Contactez</a></p>

//section.s1>.container-max>.row.flex1>.col-md-6*2

//section.s1.clearfix.flex1>.col-md-6*2

// <a class="fancybox" rel="group" href="<%=siteweb%>/images/charte/img4.jpg"><img src="<%=siteweb%>/images/charte/img4.jpg" alt="" class="center-box img-responsive" /></a>