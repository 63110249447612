/* ==========================================================================
    header-slider-2
    ========================================================================== */

    .header-slider-2 {
    position: relative;
    
    .logo-header {
        position: absolute;
        top: 10%;
        left: 10%;
        right: 10%;
        // transform: translateX(-50%);

        @media(min-width: $screen-sm-min) {
            right: auto;
        }
        
        >img {
            @extend .img-responsive;
            display: inline-block;
            width: 30rem;
        }
    }
}
    