/* ==========================================================================
	 header-header-5
	 ========================================================================== */

.header-header-5 {
	position: relative;
	padding: 0;
	margin: 0;
	height: 30rem;

	.logo-header {
		width: 100%;
		display: flex;
		z-index: 100;
		justify-content: center;
		align-items: center;

		background-color: #fff;
		
		@media(min-width: $screen-md-min) {
			position: absolute;
			top: 0;
			left: 0;
			height: 30rem;
			width: 33.333%;
		}
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			height: 30rem;
			transition: all ease-in-out .5s;
		}
		
		&:hover>img  {
			transform: scale(1.05);
		}
	}

	.slider-header {
		width: 100%;
		margin: 0;
		position: relative;
		overflow: hidden;
		max-height: 20rem;
		
		@media(min-width: $screen-md-min) {
			max-height: 34rem;
		}
		img {
			dyplay:inline-block;
			height: 30rem;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(black, .3);
		}

		div {

			&.item {
				background-size: cover;
			}
		}
	}

	.block-header {
		position: relative;
		z-index: 100;
		text-align: center;
		padding: 3rem;
		background-color: $brand-primary;
		
		@media(min-width: $screen-md-min) {
			text-align: left;
			position: absolute;
			top: 0;
			left: 33.333%;
			height: 26rem;
			padding: 5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: transparent;
		}
	}
}